import { Box, Button, FormControl, Grid, Modal, TextField, Typography } from "@mui/material";
import { Confirm, SelectAutoComplete, SelectStatus, StatusBadge, TableComponet } from "../../components";
import * as yup from 'yup';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState, useCallback } from "react";
import UsersService from "../../services/UsersService";
import { useDebounce } from "../../hooks/UseDebounce";
import CustomButton from "../../components/Button/CustomButon";
import { modalStyle } from "../../themes/stylesGlobal";
import EditarTreinamento from "./EditarTreinamento";
import { useDispatch } from "react-redux";
import { changeloading } from "../../store/actions/loading.action";
import { changeNotify } from "../../store/actions/notify.actions";

const schema = yup.object({
    norma: yup.object().nullable(),
    nome_colaborador: yup.object().nullable(),
});

const headers = [
    // { id: "id", label: "Id", props: { align: 'left' } },
    { id: "funcionario", label: "Funcionario", props: { align: 'left' } },
    { id: "treinamento", label: "Treinamento", props: { align: 'right' } },
    { id: "data_inicio", label: "Data Inicio", props: { align: 'right' } },
    { id: 'data_fim', label: 'Data Fim', props: { align: 'right' } },
    { id: 'duracao', label: 'Duração', props: { align: 'right' } },
    { id: 'status', label: 'status', props: { align: 'right' } },
    { id: 'actionRows', label: 'Ações', props: { align: 'right' } },
];

function TabelaTreinamento() {
    const { register, handleSubmit, formState: { errors }, setValue, } = useForm({ resolver: yupResolver(schema) });
    const dispatch = useDispatch();
    const [treinamentos, setTreinamentos] = useState([]);
    const [busca, setBusca] = useState('');
    const [loading, setLoading] = useState(false);
    const [tableUpdateTrigger, setTableUpdateTrigger] = useState(0);
    const [totalPage, setTotalPages] = useState(0);
    const { debounce } = useDebounce(300);
    const [openModal, setOpenModal] = useState(false);
    const [statusSelecionado, setStatusSelecionado] = useState('');

    const handleCloseModal = () => {
        setOpenModal(false);
    };
    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const [openModalTreinamento, setOpenModalTreinamento] = useState(false);
    const [dadosTreinamento, setDadosTreinamento] = useState(null);

    const Filtrar = (data) => {
        const payload = {
            data_inicio: data.data_inicio,
            data_fim: data.data_fim,
            funcionario_id: data.nome_colaborador?.id ? data.nome_colaborador.id : "",
            treinamento_id: data.norma?.id ? data.norma.id : "",
            status: statusSelecionado

        };
        setBusca(payload);
        handleCloseModal()

    }


    const fetchData = useCallback(async () => {
        setLoading(true);
        const res = await UsersService.getPagination({ search: busca }, 'controle/treinamento');
        setLoading(false);
        setTreinamentos(res.treinamentos.data || []);
        setTotalPages(res.totalPage || 0);
    }, [busca]);

    useEffect(() => {
        debounce(fetchData);
    }, [busca, tableUpdateTrigger, fetchData]);

    const handleEdit = (id) => {
        setDadosTreinamento(id);
        setOpenModalTreinamento(true);
    };
    const handleUpdateTreinamento = (updatedTreinamento) => {
        setTreinamentos((prevTreinamentos) =>
            prevTreinamentos.map((treinamento) =>
                treinamento.id === updatedTreinamento.id ? updatedTreinamento : treinamento
            )
        );
        setOpenModalTreinamento(false);
    };

    //deletar
    const handleOpenConfirmDialog = (id) => {
        let documentoId = id.id;
        setConfirmar({ documentoId, confirmDialogOpen: true });
    };
    const handleCloseConfirmDialog = () => {
        setConfirmar({ documentoId: null, confirmDialogOpen: false });
    };

    const [confirmar, setConfirmar] = useState({
        documentoId: null,
        confirmDialogOpen: false,
    });

    const handleDelete = () => {
        const idToDelete = confirmar.documentoId;
        UsersService.Delete(idToDelete, 'controle/treinamento')
            .then((res) => {
                dispatch(changeloading({ open: false }));
                dispatch(changeNotify({
                    open: true,
                    class: 'success',
                    msg: res.message,
                }));
                setTableUpdateTrigger((prev) => prev + 1);
            })
            .catch((error) => {
                dispatch(changeloading({ open: false }));
                dispatch(
                    changeNotify({
                        open: true,
                        class: 'error',
                        msg: error.message,
                    })
                );
            });
        handleCloseConfirmDialog();
    };

    return (
        <Box>
            <Box width={100}>
                <CustomButton onClick={handleOpenModal}>Filtros</CustomButton>
            </Box>
            {/* tabela
            
             data={documento.map((doc) => ({
          ...doc,
          status: <StatusBadge status={doc.status} />, // Renderiza o status com StatusBadge
        }))}
            */}
            <Box marginTop={3}>
                <TableComponet
                    headers={headers}
                    data={treinamentos.map((treinamento)=>({
                        ...treinamento,
                        status: <StatusBadge status={treinamento.status} />, // Renderiza o status com StatusBadge
                    }))}
                    labelCaption={'Nenhum treinamento encontrado!!'}
                    labelTable={'Treinamentos'}
                    handlerEditarAction={(id) => handleEdit(id)}
                    handlerDeletarAction={(event) => handleOpenConfirmDialog(event)}
                    request
                    qdtPage={totalPage}
                    loading={loading}
                    setData={setTreinamentos}
                    tableUpdateTrigger={tableUpdateTrigger}
                    handlerRequest={async (page, size) => {
                        setLoading(true);
                        UsersService.getPagination({ search: busca }, 'controle/treinamento', page, size).then((data) => {
                            setLoading(false);
                            setTreinamentos(data.treinamentos.data || []);
                            setTotalPages(data.totalPages || 0);
                            return data;
                        });
                        return [];
                    }}
                />
                {dadosTreinamento && (
                    <EditarTreinamento
                        open={openModalTreinamento}
                        onClose={() => setOpenModalTreinamento(false)}
                        dadosTreinamento={dadosTreinamento}
                        onUpdate={handleUpdateTreinamento}
                    />
                )}
                <Confirm
                    open={confirmar.confirmDialogOpen}
                    title="Deseja realmente excluir esse documento?"
                    onClose={handleCloseConfirmDialog}
                    onConfirm={handleDelete}
                />
            </Box>
            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"      >
                <Box sx={modalStyle}>
                    <form onSubmit={handleSubmit(Filtrar)}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={4} lg={2}>
                                <FormControl fullWidth>
                                    <SelectAutoComplete
                                        apiUrl="funcionario"
                                        label="Nome Colaborador"
                                        onChange={(newValue) => setValue('nome_colaborador', newValue)}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={2}>
                                <FormControl fullWidth>
                                    <SelectAutoComplete
                                        apiUrl="norma"
                                        label="Norma"
                                        onChange={(newValue) => setValue('norma', newValue)}
                                    />
                                    <Typography variant='subtitle2' color="error">{errors?.norma?.message}</Typography>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={2}>
                                <TextField
                                    label='Data inicio'
                                    type='date'
                                    variant='outlined'
                                    fullWidth
                                    size='small'
                                    InputLabelProps={{ shrink: true, }}
                                    {...register("data_inicio")}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={2}>
                                <TextField
                                    label='Data Fim'
                                    type='date'
                                    variant='outlined'
                                    fullWidth
                                    size='small'
                                    InputLabelProps={{ shrink: true, }}
                                    {...register("data_fim")}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={2}>
                                <SelectStatus value={statusSelecionado} onChange={(e) => setStatusSelecionado(e.target.value)} />
                            </Grid>

                            <Grid item xs={12} sm={6} md={4} lg={2}>
                                <Button type='submit' >Filtrar</Button>
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </Modal>

        </Box >
    )
}
export default TabelaTreinamento;