import React from 'react';
import { Box, Button, Paper, TextField, Typography } from '@mui/material';

import Logo from '../image/logo.png'

import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"


import { useDispatch } from 'react-redux';
import { login } from '../store/actions/authActions';
import { useNavigate } from 'react-router-dom';
import { changeloading } from '../store/actions/loading.action';
import UsersService from '../services/UsersService';
import { changeNotify } from '../store/actions/notify.actions';



const schema = yup
    .object({
        senha_antiga: yup.string().required(),
        senha_nova: yup.string().required().min(5),
    })

function TrocarSenha() {
    const { register, handleSubmit: onSubmit, formState: { errors }, } = useForm({ resolver: yupResolver(schema) });
    const dispatch = useDispatch();
    const navigate = useNavigate();



    const handleSubmit = async (data) => {
        dispatch(changeloading({ open: true, msg: 'Conferindo e mudando a senha..' }));
        UsersService.create(data, 'trocarsenha').then((res) => {
            dispatch(changeloading({ open: false }));
            dispatch(changeNotify({ open: true, class: 'success', msg: res.success }));
            navigate("/login");
        }).catch((error) => {
            dispatch(changeloading({ open: false }));
            console.log(error)
            dispatch(changeNotify({ open: true, class: 'error', msg: error.response.data.error }));
        });
    };




    return (
        <Box width='100vw' height='100vh' display='flex' alignItems='center' justifyContent='center'
            sx={{
                background: 'linear-gradient(to bottom, #558b2f, #8bc34a 30%, #9ccc65 70%, #c5e1a5)',
            }}>
            <Box component={Paper} elevation={4} display='flex' flexDirection='column' alignItems='center' width={380} height={370} bgcolor='#fff' >
                <Box marginTop={8}>
                    <Typography variant='h3'>Redefinir senha</Typography>
                </Box>
                <form onSubmit={onSubmit(handleSubmit)}>
                    <Box marginTop="20px">
                        <TextField
                            label="Digite a senha Antiga"
                            type="password"
                            size='small'
                            fullWidth
                            {...register("senha_antiga")}
                        />
                        <Typography variant='subtitle2'>{errors?.senha_antiga?.message}</Typography>
                    </Box>

                    <Box marginTop="20px">
                        <TextField
                            label="Digite a nova senha"
                            type="password"
                            size='small'
                            fullWidth
                            {...register("senha_nova")}
                            color="secondary"
                        />
                        <Typography variant='subtitle2'>{errors?.senha_nova?.message}</Typography>
                    </Box>
                    <Box marginTop="20px">
                        <Button type='submit' variant='contained' fullWidth>Trocar senha </Button>
                    </Box>
                </form>
            </Box>
        </Box>
    )

}

export default TrocarSenha;