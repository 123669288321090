import { Box, Paper, Typography, TextField } from '@mui/material';
import React, { useState } from 'react';
import CustomButton from '../../components/Button/CustomButon';
import UsersService from '../../services/UsersService';
import { useDispatch } from 'react-redux';
import { changeloading } from '../../store/actions/loading.action';
import { changeNotify } from '../../store/actions/notify.actions';

export default function EnviarTodos() {
    const dispatch = useDispatch();
    const [mensagem, setMensagem] = useState('');

    const handleSendMessage = () => {
        
        let data = {
            mensagem: mensagem
        }
        UsersService.create(data, 'mensagem/todos').then((res) => {
            dispatch(changeloading({ open: false, msg: 'disparando mensagens ...' }));
            dispatch(changeNotify({ open: true, class: 'success', msg: res.message }));
        }).catch((error) => {
            dispatch(changeloading({ open: false }));
            dispatch(changeNotify({ open: true, class: 'error', msg: error.response.data.error }));
        });
    };

    return (
        <Box>
            <Box component={Paper} padding={2} marginBottom={2}>
                <Typography variant='h4' sx={{ fontSize: '25px !important' }}>
                    Aqui você irá disparar mensagem para todos clientes ativos do sistema!!
                </Typography>
            </Box>

            <Box component={Paper} padding={2}>
                <TextField
                    fullWidth
                    label="Digite sua mensagem"
                    variant="outlined"
                    value={mensagem}
                    onChange={(e) => setMensagem(e.target.value)}
                    multiline
                    rows={6}
                />
                <Box marginTop={2}>

                    <CustomButton
                        onClick={handleSendMessage}
                    >
                        Enviar
                    </CustomButton>
                </Box>
            </Box>
        </Box>
    );
}
