import { Box, Button, FormControl, Grid, IconButton, InputLabel, MenuItem, Modal, Paper, Select, TextField, Tooltip, Typography } from '@mui/material';
import React, { useState } from 'react';
import * as yup from 'yup';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { CardDashboard, SelectAutoComplete } from '../../components';
import { ControlPoint } from '@mui/icons-material';
import { modalStyleInput } from '../../themes/stylesGlobal';
import { changeloading } from '../../store/actions/loading.action';
import { changeNotify } from '../../store/actions/notify.actions';
import { useDispatch } from 'react-redux';
import UsersService from '../../services/UsersService';
import CustomButton from '../../components/Button/CustomButon';

const schema = yup.object({
    norma: yup.object().required().nullable(),
    nome_colaborador: yup.object().required().nullable(),
});

function AdicionarTreinamento() {
    const dispatch = useDispatch();
    const { register, handleSubmit, formState: { errors }, setValue } = useForm({ resolver: yupResolver(schema) });
    const [openModalButton, setOpenModalButton] = useState(false);
    const [norma, setNorma] = useState('');
    const [empresaSelecionada, setEmpresaSelecionada] = useState('');  // Estado para armazenar a empresa
    const [selectKey, setSelectKey] = useState(0);
    const [selectKeyDois, setSelectKeyDois] = useState(0);
    
    const handleOpenModalButton = () => { setOpenModalButton(true); };
    const handleCloseModalButton = () => { setOpenModalButton(false); };

    const onSubmitForm = (data) => {
        dispatch(changeloading({ open: true, msg: 'Salvando..' }));
        const payload = {
            data_inicio: data.data_inicio,
            data_fim: data.data_fim,
            duracao: data.duracao,
            funcionario_id: data.nome_colaborador?.id,
            treinamento_id: data.norma?.id
        };
        UsersService.create(payload, 'controle/treinamento').then((res) => {
            dispatch(changeloading({ open: false }));
            dispatch(changeNotify({ open: true, class: 'success', msg: res.message }));
            setOpenModalButton(false);
            setSelectKey(prevKey => prevKey + 1);
            setSelectKeyDois(prevKey => prevKey + 1);
        }).catch((error) => {
            console.log(error)
            dispatch(changeloading({ open: false }));
            dispatch(changeNotify({ open: true, class: 'error', msg: error.response.data.message }));
        });
    };

    const handleFuncionarioChange = (newValue) => {
        setValue('nome_colaborador', newValue);
        // Armazena o nome da empresa no estado quando o funcionário for selecionado
        setEmpresaSelecionada(newValue?.nome_razao_social || '');
    };

    const handleSalvarNorma = () => {
        dispatch(changeloading({ open: true, msg: 'Salvando..' }));
        let objNorma = {
            nome: norma
        };
        UsersService.create(objNorma, 'norma').then((res) => {
            dispatch(changeloading({ open: false }));
            dispatch(changeNotify({ open: true, class: 'success', msg: res.message }));
            setOpenModalButton(false);
            setSelectKey(prevKey => prevKey + 1);
        }).catch((error) => {
            dispatch(changeloading({ open: false }));
            dispatch(changeNotify({ open: true, class: 'error', msg: error.message }));
        });
    }

    return (
        <Box>
            <Box component={Paper} padding={3} elevation={4}>
                <form onSubmit={handleSubmit(onSubmitForm)}>
                    <Grid container spacing={2}>
                    {empresaSelecionada && (
                            <Grid item xs={12}>
                                <Typography  variant="h5">
                                    Funcionario da empresa: {empresaSelecionada}
                                </Typography>
                            </Grid>
                        )}
                        {/* Select para selecionar o funcionário */}
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <FormControl fullWidth>
                                <SelectAutoComplete
                                    key={selectKeyDois}
                                    apiUrl="funcionario"
                                    label="Nome Colaborador"
                                    onChange={handleFuncionarioChange}  // Usando o novo handler
                                />
                                <Typography variant='subtitle2' color="error">{errors?.nome_colaborador?.message}</Typography>
                            </FormControl>
                        </Grid>

                     
                       

                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Box display='flex'>
                                <FormControl fullWidth>
                                    <SelectAutoComplete
                                        key={selectKey}
                                        apiUrl="norma"
                                        label="Norma"
                                        onChange={(newValue) => setValue('norma', newValue)}
                                    />
                                    <Typography variant='subtitle2' color="error">{errors?.norma?.message}</Typography>
                                </FormControl>
                                <Tooltip title="Adicionar Norma">
                                    <IconButton onClick={handleOpenModalButton}>
                                        <ControlPoint color="success" />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4} lg={2}>
                            <TextField
                                label='Data inicio'
                                type='date'
                                variant='outlined'
                                fullWidth
                                size='small'
                                InputLabelProps={{ shrink: true, }}
                                {...register("data_inicio")}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={4} lg={2}>
                            <TextField
                                label='Data Fim'
                                type='date'
                                variant='outlined'
                                fullWidth
                                size='small'
                                InputLabelProps={{ shrink: true, }}
                                {...register("data_fim")}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={4} lg={2}>
                            <FormControl fullWidth>
                                <InputLabel >
                                    Duração
                                </InputLabel>
                                <Select
                                    label='Duração'
                                    size='small'
                                    {...register("duracao")}
                                >
                                    <MenuItem value={12}>12 meses</MenuItem>
                                    <MenuItem value={24}>24 meses</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4} lg={2}>
                            <CustomButton type='submit' variant='contained'>Salvar</CustomButton>
                        </Grid>

                    </Grid>
                </form>

                <Modal
                    open={openModalButton}
                    onClose={handleCloseModalButton}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={modalStyleInput}>
                        <Box>
                            <TextField
                                label='Norma'
                                variant='outlined'
                                fullWidth
                                size='small'
                                value={norma}
                                onChange={(e) => setNorma(e.target.value)}
                            />
                            <Box marginTop={2}>
                                <Button onClick={handleSalvarNorma}>Salvar</Button>
                            </Box>
                        </Box>
                    </Box>
                </Modal>
            </Box>
        </Box>
    );
}

export default AdicionarTreinamento;
