import React from 'react';
import { Box } from '@mui/material';

const StatusBadge = ({ status }) => {
  const getStatusColor = (status) => {
    switch (status) {
      case 'vencido':
        return { backgroundColor: '#f44336', color: '#fff' }; // Vermelho
      case 'alerta':
        return { backgroundColor: '#ff9800', color: '#fff' }; // Amarelo
      case 'ativo':
        return { backgroundColor: '#4caf50', color: '#fff' }; // Verde
      default:
        return { backgroundColor: '#e0e0e0', color: '#000' }; // Cinza para status desconhecido
    }
  };

  const styles = {
    ...getStatusColor(status),
    borderRadius: '12px', // Deixa o badge arredondado
    padding: '2px 8px', // Padding interno
    fontSize: '12px', // Texto pequeno
    fontWeight: 'bold', // Deixa o texto destacado
    display: 'inline-block', // Garante que o tamanho seja limitado ao conteúdo
    textTransform: 'capitalize', // Primeira letra maiúscula
  };

  return <Box sx={styles}>{status}</Box>;
};

export default StatusBadge;
