import React, { useState, useEffect, useCallback } from 'react';
import { Box, Button, FormControl, Modal, Grid, TextField, Typography, InputLabel, MenuItem, Select } from "@mui/material";
import { TableComponet, Confirm, SelectAutoComplete } from "../../components";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from 'yup';
import { useDebounce } from "../../hooks/UseDebounce";
import { useDispatch } from "react-redux";
import { changeloading } from "../../store/actions/loading.action";
import { changeNotify } from "../../store/actions/notify.actions";
import UsersService from "../../services/UsersService";
import EditarIntegracao from "./EditarIntegracao";
import CustomButton from "../../components/Button/CustomButon";
import { modalStyle } from '../../themes/stylesGlobal';

const schema = yup.object({
    nome_colaborador: yup.object().nullable(),
    empresa: yup.object().nullable(),
    status: yup.string().nullable(),
});

const headers = [
    { id: "funcionario", label: "Funcionário", props: { align: 'left' } },
    { id: "empresa", label: "Empresa", props: { align: 'right' } },
    { id: "status", label: "Status", props: { align: 'right' } },
    { id: "data_elaboracao", label: "Data de Elaboração", props: { align: 'right' } },
    { id: "ficha_epi", label: "Ficha de EPI", props: { align: 'right' } },
    { id: 'actionRows', label: 'Ações', props: { align: 'right' } },
];

function TabelaIntegracao() {
    const { register, handleSubmit, formState: { errors }, setValue } = useForm({ resolver: yupResolver(schema) });
    const dispatch = useDispatch();
    const [integracoes, setIntegracoes] = useState([]);
    const [busca, setBusca] = useState('');
    const [loading, setLoading] = useState(false);
    const [tableUpdateTrigger, setTableUpdateTrigger] = useState(0);
    const [totalPage, setTotalPages] = useState(0);
    const { debounce } = useDebounce(300);
    const [openModal, setOpenModal] = useState(false);
    const [statusSelecionado, setStatusSelecionado] = useState('');

    const handleCloseModal = () => setOpenModal(false);
    const handleOpenModal = () => setOpenModal(true);

    const [openModalIntegracao, setOpenModalIntegracao] = useState(false);
    const [dadosIntegracao, setDadosIntegracao] = useState(null);

    const Filtrar = (data) => {
        const payload = {
            funcionario_id: data.nome_colaborador?.id || "",
            cliente_id: data.empresa?.id || "",
            status: data.status || ""
        };
        setBusca(payload);
        handleCloseModal();
    };

    const fetchData = useCallback(async () => {
        setLoading(true);
        const res = await UsersService.getPagination({ search: busca }, 'integracao');
        setLoading(false);
        setIntegracoes(res.integracoes.data || []);
        setTotalPages(res.totalPage || 0);
    }, [busca]);

    useEffect(() => {
        debounce(fetchData);
    }, [busca, tableUpdateTrigger, fetchData]);

    const handleEdit = (id) => {
        setDadosIntegracao(id);
        setOpenModalIntegracao(true);
    };

    const handleUpdateIntegracao = (updatedIntegracao) => {
        setIntegracoes((prevIntegracoes) =>
            prevIntegracoes.map((integracao) =>
                integracao.id === updatedIntegracao.id ? updatedIntegracao : integracao
            )
        );
        setOpenModalIntegracao(false);
    };

    // Deletar
    const handleOpenConfirmDialog = (id) => {
        let documentoId = id.id;
        setConfirmar({ documentoId, confirmDialogOpen: true });
    };

    const handleCloseConfirmDialog = () => {
        setConfirmar({ documentoId: null, confirmDialogOpen: false });
    };

    const [confirmar, setConfirmar] = useState({
        documentoId: null,
        confirmDialogOpen: false,
    });

    const handleDelete = () => {
        const idToDelete = confirmar.documentoId;
        UsersService.Delete(idToDelete, 'integracao')
            .then((res) => {
                dispatch(changeloading({ open: false }));
                dispatch(changeNotify({ open: true, class: 'success', msg: res.message }));
                setTableUpdateTrigger((prev) => prev + 1);
            })
            .catch((error) => {
                dispatch(changeloading({ open: false }));
                dispatch(changeNotify({ open: true, class: 'error', msg: error.message }));
            });
        handleCloseConfirmDialog();
    };
  
    return (
        <Box>
            <Box width={100}>
                <CustomButton onClick={handleOpenModal}>Filtros</CustomButton>
            </Box>
            {/* Tabela */}
            <Box marginTop={3}>
                <TableComponet
                    headers={headers}
                    data={integracoes}
                    labelCaption={'Nenhuma integração encontrada!'}
                    labelTable={'Integrações'}
                    handlerEditarAction={(id) => handleEdit(id)}
                    handlerDeletarAction={(event) => handleOpenConfirmDialog(event)}
                    request
                    qdtPage={totalPage}
                    loading={loading}
                    setData={setIntegracoes}
                    tableUpdateTrigger={tableUpdateTrigger}
                    handlerRequest={async (page, size) => {
                        setLoading(true);
                        UsersService.getPagination({ search: busca }, 'integracao', page, size).then((data) => {
                            setLoading(false);
                            setIntegracoes(data.data || []);
                            setTotalPages(data.totalPages || 0);
                            return data;
                        });
                        return [];
                    }}
                />
                {dadosIntegracao && (
                    
                    <EditarIntegracao
                        open={openModalIntegracao}
                        onClose={() => setOpenModalIntegracao(false)}
                        dadosIntegracao={dadosIntegracao}
                        onUpdate={handleUpdateIntegracao}
                    />
                )}
                <Confirm
                    open={confirmar.confirmDialogOpen}
                    title="Deseja realmente excluir essa integração?"
                    onClose={handleCloseConfirmDialog}
                    onConfirm={handleDelete}
                />
            </Box>
            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box sx={modalStyle}>
                    <form onSubmit={handleSubmit(Filtrar)}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={5} lg={4}>
                                <FormControl fullWidth>
                                    <SelectAutoComplete
                                        apiUrl="funcionario"
                                        label="Nome Colaborador"
                                        onChange={(newValue) => setValue('nome_colaborador', newValue)}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={5} lg={4}>
                                <FormControl fullWidth>
                                    <SelectAutoComplete
                                        apiUrl="cliente"
                                        label="Empresa"
                                        onChange={(newValue) => setValue('empresa', newValue)}
                                    />
                                    <Typography variant='subtitle2' color="error">{errors?.empresa?.message}</Typography>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={5} lg={3}>
                                <FormControl fullWidth>
                                    <InputLabel>Status</InputLabel>
                                    <Select
                                        variant='outlined'
                                        size='small'
                                        {...register('status')}
                                    >
                                        <MenuItem value="contratado">Contratado</MenuItem>
                                        <MenuItem value="demitido">Demitido</MenuItem>
                                    </Select>
                                    <Typography variant='subtitle2' color="error">{errors?.status?.message}</Typography>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={5} lg={2}>
                                <Button type='submit'>Filtrar</Button>
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </Modal>
        </Box>
    );
}

export default TabelaIntegracao;
