import { Box, TextField, InputAdornment, Modal, Typography, Grid, Autocomplete, CircularProgress, Button } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import React, { useEffect, useState, useCallback } from 'react';
import { Confirm, SelectAno, SelectMes, SelectStatus, StatusBadge, TableComponet } from '../../components';
import UsersService from '../../services/UsersService';
import { useDebounce } from '../../hooks/UseDebounce';
import { useDispatch } from 'react-redux';
import { changeloading } from '../../store/actions/loading.action';
import { changeNotify } from '../../store/actions/notify.actions';
import * as yup from 'yup';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { format } from 'date-fns';

import { useParams } from 'react-router-dom';
import CustomButton from '../../components/Button/CustomButon';
import { modalStyle, modalStylePesquisar } from '../../themes/stylesGlobal';



const schema = yup.object({
  nome: yup.string().required(),
  tipo_documentacao_id: yup.string().required(),
  cliente_id: yup.string().required(),
  data_vencimento: yup.string().required(),
});


const headers = [
  {
    id: "nomeCliente",
    label: "Nome do Cliente",
    props: {
      align: 'left',
    },
  },
  {
    id: "nome",
    label: "Nome do Documento",
    props: {
      align: 'left',
    },
  },
  {
    id: "tipo_documento",
    label: "Tipo de Documento",
    props: {
      align: 'right',
    },
  },
  {
    id: 'data_vencimento',
    label: 'Data de Vencimento',
    props: {
      align: 'right',
    },
  },
  {
    id: 'data_cadastro',
    label: 'Data de Cadastro',
    props: {
      align: 'right',
    },
  },
  {
    id: 'status',
    label: 'Status',
    props: {
      align: 'right',
    },
  },
  {
    id: 'actionRows',
    label: 'Ações',
    props: {
      align: 'right',
    },
  },
];



const statusStyles = {
  ativo: { color: 'green' },
  alerta: { color: '#e4c115' },
  vencido: { color: 'red' },
};

const formatStatus = (status) => {
  return (
    <span style={statusStyles[status]}>
      {status.charAt(0).toUpperCase() + status.slice(1)}
    </span>
  );
};



function TableDocumentacao({ tableUpdateTriggerPai }) {
  const { register, handleSubmit, formState: { errors }, setValue, getValues, reset } = useForm({ resolver: yupResolver(schema) });
  const dispatch = useDispatch();
  const { status } = useParams();
  const [documento, setDocumento] = useState([]);
  const [totalPage, setTotalPages] = useState(0);
  const [tableUpdateTrigger, setTableUpdateTrigger] = useState(0);
  const [documentoId, setDocumentoId] = useState(null);
  const [busca, setBusca] = useState(status || '');
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectedDocumento, setSelectedDocumento] = useState(null);

  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const [openEmpresa, setOpenEmpresa] = useState(false);
  const [optionsEmpresa, setOptionsEmpresa] = useState([]);
  const [inputValueEmpresa, setInputValueEmpresa] = useState('');
  const [loadingEmpresa, setLoadingEmpresa] = useState(false);
  const [selectedOptionEmpresa, setSelectedOptionEmpresa] = useState(null);

  //modal pesquisar
  const [openPesquisar, setOpenPesquisar] = React.useState(false);
  const handleOpenPesquisar = () => setOpenPesquisar(true);
  const handleClosePesquisar = () => setOpenPesquisar(false);
  const [nomeDocumento, setNomeDocumento] = useState('');
  const [dataCadastro, setDataCadastro] = useState('');
  const [dataVencimento, setDataVencimento] = useState('');
  const [mesSelecionado, setMesSelecionado] = useState('');
  const [anoSelecionado, setAnoSelecionado] = useState('');
  const [statusSelecionado, setStatusSelecionado] = useState('');


  const [currentPage, setCurrentPage] = useState(1);


  const { debounce } = useDebounce(300);

  const fetchData = useCallback(async (page = 1) => {
    setLoading(true);
    const res = await UsersService.getPagination({ search: busca }, 'documento', page, 10); // Passa o pageSize
    setLoading(false);
    setDocumento(res.documentos || []);
    setTotalPages(res.totalPage || 0);
  }, [busca]);

  useEffect(() => {
    debounce(fetchData);
  }, [busca, tableUpdateTrigger, tableUpdateTriggerPai, debounce, fetchData]);

  const handleEdit = async (documentoId) => {
    try {
      const documentoData = await UsersService.getById(documentoId.id, 'documento/buscar');
      setDocumentoId(documentoData.id);
      setValue('nome', documentoData.nome);
      setValue('tipo_documentacao_id', documentoData.tipo_documentacao_id);
      setValue('cliente_id', documentoData.cliente_id);
      setValue('data_vencimento', documentoData.data_vencimento);

      // Defina o valor dos Autocomplete baseando-se nas opções disponíveis
      const tipoDocOption = {
        id: documentoData.tipo_documentacao_id,
        nome: documentoData.nomeTipoDocumento
      };
      setSelectedOption(tipoDocOption);

      const clienteOption = {
        id: documentoData.cliente_id,
        nome_razao_social: documentoData.nomeCliente
      };
      setSelectedOptionEmpresa(clienteOption);

      setOpenModal(true);
    } catch (error) {
      dispatch(changeNotify({
        open: true,
        class: 'error',
        msg: error.message,
      }));
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedDocumento(null);
    reset();
  };

  const handleOpenConfirmDialog = (id) => {
    let documentoId = id.id;
    setConfirmar({ documentoId, confirmDialogOpen: true });
  };

  const handleCloseConfirmDialog = () => {
    setConfirmar({ documentoId: null, confirmDialogOpen: false });
  };

  const [confirmar, setConfirmar] = useState({
    documentoId: null,
    confirmDialogOpen: false,
  });

  const handleDelete = () => {
    const idToDelete = confirmar.documentoId;
    UsersService.Delete(idToDelete, 'documento')
      .then((res) => {
        dispatch(changeloading({ open: false }));
        dispatch(changeNotify({
          open: true,
          class: 'success',
          msg: res.message,
        }));
        setTableUpdateTrigger((prev) => prev + 1);
      })
      .catch((error) => {
        dispatch(changeloading({ open: false }));
        dispatch(
          changeNotify({
            open: true,
            class: 'error',
            msg: error.message,
          })
        );
      });
    handleCloseConfirmDialog();
  };

  const buscaDocumento = useCallback(async (query) => {
    setLoading(true);
    const res = await UsersService.getPagination({ search: query }, 'trazer/tipo');
    setOptions(res.tipo || []);
    setLoading(false);
  }, []);

  const buscaEmpresa = useCallback(async (query) => {
    setLoadingEmpresa(true);
    const res = await UsersService.getPagination({ search: query }, 'cliente');
    setOptionsEmpresa(res.clientes || []);
    setLoadingEmpresa(false);
  }, []);


  useEffect(() => {
    debounce(() => {
      if (inputValue.trim() !== '') {
        buscaDocumento(inputValue);
      } else {
        setOptions([]);
      }
    });
  }, [inputValue, debounce, buscaDocumento]);

  useEffect(() => {
    if (open) {
      buscaDocumento('');
    } else {
      setOptions([]);
    }
  }, [open, buscaDocumento]);

  useEffect(() => {
    debounce(() => {
      if (inputValueEmpresa.trim() !== '') {
        buscaEmpresa(inputValueEmpresa);
      } else {
        setOptionsEmpresa([]);
      }
    });
  }, [inputValueEmpresa, debounce, buscaEmpresa]);

  useEffect(() => {
    if (openEmpresa) {
      buscaEmpresa('');
    } else {
      setOptionsEmpresa([]);
    }
  }, [openEmpresa, buscaEmpresa]);


  const onSubmitForm = async (data) => {
    dispatch(changeloading({ open: true, msg: 'Salvando...' }));
  
    try {
      const res = await UsersService.update(documentoId, data, 'documento');
      dispatch(changeloading({ open: false }));
      dispatch(changeNotify({ open: true, class: 'success', msg: res.message }));
  
      // Atualiza apenas o item editado no estado
      setDocumento((prevDocs) =>
        prevDocs.map((doc) =>
          doc.id === documentoId
            ? { ...doc, ...data, status: res.status || doc.status } // Atualiza os dados no estado
            : doc
        )
      );
      await fetchData(currentPage)
      setOpenModal(false); // Fecha o modal
    } catch (error) {
      dispatch(changeloading({ open: false }));
      dispatch(
        changeNotify({
          open: true,
          class: 'error',
          msg: error.response?.data?.error || 'Erro desconhecido',
        })
      );
    }
  };
  

  const exportToExcel = (data) => {
    if (!data || data.length === 0) {
      alert("Nenhum dado para exportar");
      return;
    }

    // Mapear os dados para formatar as datas e remover colunas desnecessárias
    const formattedData = data.map(doc => ({
      ID: doc.id,
      'Nome do Cliente': doc.nomeCliente,
      'Tipo de Documento': doc.tipo_documento,
      'Data de Vencimento': doc.data_vencimento,
      'Data de Cadastro': doc.data_cadastro,
      Status: doc.status,
    }));

    // Cria a planilha a partir dos dados formatados
    const worksheet = XLSX.utils.json_to_sheet(formattedData);

    // Define a largura das colunas
    const columnWidths = [
      { wch: 10 }, // largura da coluna A
      { wch: 30 }, // largura da coluna B
      { wch: 30 }, // largura da coluna C
      { wch: 15 }, // largura da coluna D
      { wch: 15 }, // largura da coluna E
      { wch: 10 }, // largura da coluna F
    ];

    worksheet['!cols'] = columnWidths;

    // Aplica estilo ao cabeçalho
    const range = XLSX.utils.decode_range(worksheet['!ref']);
    for (let C = range.s.c; C <= range.e.c; ++C) {
      const address = XLSX.utils.encode_col(C) + "1";
      if (!worksheet[address]) continue;
      worksheet[address].s = { font: { bold: true } };
    }

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Documentos');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });

    // Obtém a data e hora atual formatada
    const now = new Date();
    const formattedDate = format(now, 'dd/MM/yyyy');

    // Salva o arquivo Excel com a data e hora no nome
    saveAs(blob, `documentacoes_${formattedDate}.xlsx`);
  };

  const buscarParaExcel = () => {
    let data = {
      nomeDocumento: nomeDocumento,
      dataCadastro: dataCadastro,
      dataVencimento: dataVencimento,
      mesSelecionado: mesSelecionado,
      anoSelecionado: anoSelecionado,
      statusSelecionado: statusSelecionado
    };

    dispatch(changeloading({ open: true, msg: 'carregando excel' }));

    UsersService.getPesquisa(data, 'filtros/documentos')
      .then((res) => {
        if (res && res.documentos) {
          exportToExcel(res.documentos);
        } else {
          console.error("Nenhum dado recebido do servidor");
          dispatch(changeNotify({ open: true, class: 'error', msg: 'Nenhum dado recebido do servidor' }));
        }
        dispatch(changeloading({ open: false }));
      })
      .catch((error) => {
        dispatch(changeloading({ open: false }));
        dispatch(changeNotify({ open: true, class: 'error', msg: error.response?.data?.error || 'Erro desconhecido' }));
      });
  };


  const handleAtivo = async (documento) => {
    const updatedAtivo = !documento.ativo;

    try {
      await UsersService.update(documento.id, { ativo: updatedAtivo }, 'documento/atualizar-ativo');
      dispatch(changeNotify({
        open: true,
        class: 'success',
        msg: 'Atualizado com sucesso',
      }));

      // Atualiza o estado localmente sem fazer outra requisição
      setDocumento((prevDocs) => prevDocs.map((doc) =>
        doc.id === documento.id ? { ...doc, ativo: updatedAtivo } : doc
      ));
    } catch (error) {
      dispatch(changeNotify({
        open: true,
        class: 'error',
        msg: error.response?.data?.error || 'Erro desconhecido',
      }));
    }
  }

  const filtrarPesquisa = () => {
    // Monta os dados da requisição
    let data = {
      nomeDocumento: nomeDocumento,
      dataCadastro: dataCadastro,
      dataVencimento: dataVencimento,
      mesSelecionado: mesSelecionado,
      anoSelecionado: anoSelecionado,
      statusSelecionado: statusSelecionado
    };


    console.log(data)
    UsersService.getPesquisa(data, 'filtros/documentos')
      .then((res) => {
        setDocumento(res.documentos || []);
        handleClosePesquisar();
      })
      .catch((error) => {
        dispatch(changeloading({ open: false }));
        dispatch(
          changeNotify({
            open: true,
            class: 'error',
            msg: error.message,
          })
        );
      });
  };


  return (
    <Box>
      <Box display='flex' >

        <Box width='40%'>
          <TextField
            label="Pesquisar"
            value={busca}
            onChange={(e) => setBusca(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">""
                  <SearchIcon />
                </InputAdornment>
              ),
              sx: {
                lineHeight: '1.6', // ajusta a altura da linha para centralizar o texto verticalmente
              },
              inputProps: {
                style: {
                  padding: '10px 10px', // ajusta o padding interno do input para centralizar o texto
                },
              },
            }}
            InputLabelProps={{
              style: {
                lineHeight: '1.6',
              }
            }}
            variant="outlined"
            fullWidth
            margin="normal"
          />
        </Box>
        <Box marginTop='18px' marginLeft={4} width='150px'>
          <Button size='small' sx={{ color: '#1a237e!important' }} onClick={handleOpenPesquisar}>Filtrar</Button>
        </Box>

      </Box>


      <Confirm
        open={confirmar.confirmDialogOpen}
        title="Deseja realmente excluir esse documento?"
        onClose={handleCloseConfirmDialog}
        onConfirm={handleDelete}
      />


      <TableComponet
        headers={headers}
        data={documento.map((doc) => ({
          ...doc,
          status: <StatusBadge status={doc.status} />, // Renderiza o status com StatusBadge
        }))}
        labelCaption="Nenhuma Documentação encontrada!"
        labelTable="Documentações"
        handlerEditarAction={(documentoId) => handleEdit(documentoId)}
        handlerAtivoAction={(documento) => handleAtivo(documento)}
        handlerDeletarAction={(event) => handleOpenConfirmDialog(event)}
        request
        qdtPage={totalPage}
        loading={loading}
        setData={setDocumento}
        tableUpdateTrigger={tableUpdateTrigger}
        handlerRequest={async (page, size) => {
          setCurrentPage(page); // Atualiza o estado da página atual
          setLoading(true);
          const res = await UsersService.getPagination(
            { search: busca },
            "documento",
            page,
            size
          );
          setLoading(false);
          setDocumento(res.documentos || []);
          setTotalPages(res.totalPage || 0);
          return res.documentos || [];
        }}
        
      />

      {/* Modal pesquisar */}
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <form onSubmit={handleSubmit(onSubmitForm)}>
            <Grid container spacing={2}>

              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                  label='Nome do Documento'
                  variant='outlined'
                  fullWidth
                  size='small'
                  {...register("nome")}
                />
                <Typography variant='subtitle2'>{errors?.nome?.message}</Typography>
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Box display='flex'>
                  <Autocomplete
                    fullWidth
                    open={open}
                    onOpen={() => setOpen(true)}
                    onClose={() => setOpen(false)}
                    getOptionLabel={(option) => option.nome}
                    options={options}
                    loading={loading}
                    inputValue={inputValue}
                    onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
                    value={selectedOption}
                    onChange={(event, newValue) => {
                      setSelectedOption(newValue);
                      setValue("tipo_documentacao_id", newValue ? newValue.id : '');
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Tipo"
                        size='small'
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {loading ? <CircularProgress color="inherit" size={20} /> : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}

                      />
                    )}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Box display='flex'>
                  <Autocomplete
                    fullWidth
                    open={openEmpresa}
                    onOpen={() => setOpenEmpresa(true)}
                    onClose={() => setOpenEmpresa(false)}
                    getOptionLabel={(option) => option.nome_razao_social}
                    options={optionsEmpresa}
                    loading={loadingEmpresa}
                    inputValue={inputValueEmpresa}
                    onInputChange={(event, newInputValue) => setInputValueEmpresa(newInputValue)}
                    value={selectedOptionEmpresa}
                    onChange={(event, newValue) => {
                      setSelectedOptionEmpresa(newValue);
                      setValue("cliente_id", newValue ? newValue.id : '');
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Empresa"
                        size='small'
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {loadingEmpresa ? <CircularProgress color="inherit" size={20} /> : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}

                      />
                    )}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} sm={6} md={3} lg={3}>
                <TextField
                  label='Data de Vencimento'
                  type='date'
                  variant='outlined'
                  fullWidth
                  size='small'
                  InputLabelProps={{ shrink: true, }}
                  {...register("data_vencimento")}
                />
                <Typography variant='subtitle2'>{errors?.data_vencimento?.message}</Typography>
              </Grid>

              <Grid item xs={12} sm={6} md={3} lg={2}>
                <CustomButton type='submit'>Salvar</CustomButton>
              </Grid>

            </Grid>
          </form>

        </Box>

      </Modal>

      <Modal
        open={openPesquisar}
        onClose={handleClosePesquisar}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStylePesquisar}>
          <Grid container spacing={2}>

            <Grid container spacing={1} marginLeft={1}>
              <Grid item xs={12} sm={6} md={4} lg={2}>
                <SelectAno value={anoSelecionado} onChange={(e) => setAnoSelecionado(e.target.value)} />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={2}>
                <SelectMes value={mesSelecionado} onChange={(e) => setMesSelecionado(e.target.value)} />
              </Grid>
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                label='Nome do Documento'
                variant='outlined'
                fullWidth
                size='small'
                value={nomeDocumento}
                onChange={(e) => setNomeDocumento(e.target.value)}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <SelectStatus value={statusSelecionado} onChange={(e) => setStatusSelecionado(e.target.value)} />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                label='Data de Cadastro'
                type='date'
                variant='outlined'
                fullWidth
                size='small'
                InputLabelProps={{ shrink: true, }}
                value={dataCadastro}
                onChange={(e) => setDataCadastro(e.target.value)}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                label='Data de Vencimento'
                type='date'
                variant='outlined'
                fullWidth
                size='small'
                InputLabelProps={{ shrink: true, }}
                value={dataVencimento}
                onChange={(e) => setDataVencimento(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={3}></Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <CustomButton bgColor='#5e5e5e' onClick={buscarParaExcel}>Exportar excel</CustomButton>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <CustomButton onClick={filtrarPesquisa}>Buscar</CustomButton>
            </Grid>

          </Grid>
        </Box>

      </Modal>
    </Box>
  );
}

export default TableDocumentacao;
