import { Box } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { TableComponet } from '../../components'
import UsersService from '../../services/UsersService';
import { useDispatch } from 'react-redux';
import { changeloading } from '../../store/actions/loading.action';
import { useDebounce } from '../../hooks/UseDebounce';


const headers = [
    { id: 'enviado_para', label: 'Enviado Para:', props: { align: 'left', style: { width: '200px' } } },
    { id: 'telefone', label: 'Telefone', props: { align: 'right' } },
    { id: 'whatsapp', label: 'Whatsapp', props: { align: 'right' } },
    { id: 'mensagem_enviada', label: 'Mensagem Enviada', props: { align: 'right' } },
    { id: 'fromMe', label: 'Status', props: { align: 'right' }, render: (row) => row.fromMe ? 'Enviado' : 'Não Enviado' },
    { id: 'created_at_formatted', label: 'Data Envio', props: { align: 'right' }}
];

export default function Monitoramento() {

    const [monitoramento, setMonitoramento] = useState([]);
    const [totalPage, setTotalPages] = useState(0);
    const [busca, setBusca] = useState('');
    const [loading, setLoading] = useState(false);
    const [tableUpdateTrigger, setTableUpdateTrigger] = useState(0);

    const dispatch = useDispatch();
    const { debounce } = useDebounce(300);

    const fetchData = useCallback(async () => {
        setLoading(true);
        const res = await UsersService.getPagination({ search: busca }, 'monitoramento');
        setLoading(false);
        setMonitoramento(res.data || []); 
        setTotalPages(res.total_pages || 0); 
    }, [busca]);

    useEffect(() => {
        debounce(fetchData);
    }, [ tableUpdateTrigger, fetchData]);
    console.log('sfdsafsf',monitoramento);
    return (
        <Box>
      <TableComponet
                    headers={headers}
                    data={monitoramento}
                    labelCaption={'Nenhuma integração encontrada!'}
                    labelTable={'Monitoramento'}
                    handlerEditarAction={''}
                    handlerDeletarAction={''}
                    request
                    qdtPage={totalPage}
                    loading={loading}  
                    setData={setMonitoramento}                
                    tableUpdateTrigger={tableUpdateTrigger}
                    handlerRequest={async (page, size) => {
                        setLoading(true);
                        UsersService.getPagination({ search: busca }, 'monitoramento', page, size).then((data) => {
                            console.log('afA', data);
                            setLoading(false);
                            setMonitoramento(data.data || []); // Corrige o acesso aos dados
                            setTotalPages(data.total_pages || 0); // Corrige o acesso ao total de páginas
                            return data;
                        });
                        return [];
                    }}
                />
        </Box>
    )
}
