import { Box, Modal, Paper, Typography } from '@mui/material';
import React, { useState } from 'react';
import CustomButton from '../../../components/Button/CustomButon';

import { modalStyle } from '../../../themes/stylesGlobal';
import CadastroFuncionario from './CadastroFuncionario';
import TabelaFuncionario from './TabelaFuncionario';



function Funcionarios() {
    const [openModal, setOpenModal] = useState(false);
    const [tableUpdateTrigger, setTableUpdateTrigger] = useState(0);

    const handleCloseModal = () => {
        setOpenModal(false);
    };
    const handleOpenModal = () => {
        setOpenModal(true);
    };
    const handleCadastroConcluido = () => {
        setTableUpdateTrigger(prev => prev + 1);
        handleCloseModal();
    };
    return (
        <Box>
            <Box component={Paper} padding={1} display='flex' alignItems='center' justifyContent='space-between'>
                <Box>
                    <Typography variant='h3'>Funcionarios</Typography>
                </Box>
                <Box width='250px'>
                    <CustomButton onClick={handleOpenModal}>Adicionar Funcionario</CustomButton>
                </Box>
            </Box>
            <Box>
                <TabelaFuncionario tableUpdateTriggerPai={tableUpdateTrigger} />
            </Box>
            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"      >
               <Box sx={{
                    ...modalStyle,
                    maxHeight: '80%',
                    overflowY: 'auto', 
                }}>
                   
                       <CadastroFuncionario onCadastroConcluido={handleCadastroConcluido}/> 
                 
                </Box>
            </Modal>
        </Box>
    );
}

export default Funcionarios;
