import React from 'react'
import { Box, Paper, Typography } from '@mui/material'
import ImgFundo from '../../image/fundo.webp'

export default function PgBemVindo() {
    return (
        <Box>
            <Box component={Paper} padding={2} marginBottom={5}>
                <Typography variant='h1'>Seja Bem Vindo ao Sistema Asso Consutoria</Typography>
            </Box>
            <Box >
            <img
                    src={ImgFundo}
                    style={{
                        width: '100%',
                        height: '565px',
                        objectPosition: 'top',
                    }}
                    alt="Fundo"
                />
            </Box>
        </Box>
    )
}
