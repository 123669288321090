import { Box, TextField, InputAdornment, Modal } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import React, { useEffect, useState, useCallback } from 'react';
import { Confirm, TableComponet } from '../../../components';
import UsersService from '../../../services/UsersService';
import { useDebounce } from '../../../hooks/UseDebounce';
import { useDispatch } from 'react-redux';
import { changeloading } from '../../../store/actions/loading.action';
import { changeNotify } from '../../../store/actions/notify.actions';
import { modalStyle } from '../../../themes/stylesGlobal';

import EditFuncionario from './EditFuncionario';

const headers = [
    { id: 'nome', label: 'Nome', props: { align: 'left', style: { width: '200px' } } },
    { id: 'nome_razao_social', label: 'Empresa', props: { align: 'left', style: { width: '200px' } } },
    { id: 'cpf', label: 'CPF', props: { align: 'right' } },
    { id: 'telefone', label: 'Telefone', props: { align: 'right', style: { width: '150px' } } },
    { id: 'email', label: 'Email', props: { align: 'right' } },
    { id: 'endereco', label: 'Endereço', props: { align: 'right', style: { width: '150px' } } },
    { id: 'complemento', label: 'Complemento', props: { align: 'right' } },
    { id: 'bairro', label: 'Bairro', props: { align: 'right' } },
    { id: 'cidade', label: 'Cidade', props: { align: 'right' } },
    { id: 'estado', label: 'Estado', props: { align: 'right' } },
    { id: 'actionRows', label: 'Ações', props: { align: 'right' } },
];



function TabelaFuncionario({ tableUpdateTriggerPai }) {
    const dispatch = useDispatch();
    const [funcionarios, setFuncionarios] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalPage, setTotalPages] = useState(0);
    const [tableUpdateTrigger, setTableUpdateTrigger] = useState(0);

    const [busca, setBusca] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const [selectedFuncionario, setSelectedFuncionario] = useState(null);

    const { debounce } = useDebounce(300);

    const fetchData = useCallback(async () => {
        setLoading(true);
        const res = await UsersService.getPagination({ search: busca }, 'funcionario');
        setLoading(false);
        setFuncionarios(res.funcionarios || []);
        setTotalPages(res.totalPage || 0);
    }, [busca]);

    useEffect(() => {
        debounce(fetchData);
    }, [busca, tableUpdateTrigger, tableUpdateTriggerPai, debounce, fetchData]);

    const handleEdit = (funcionarioId) => {
        setSelectedFuncionario(funcionarioId);
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setSelectedFuncionario(null);
    };



    // Confirmação de exclusão
    const [confirmar, setConfirmar] = useState({ funcionarioId: null, confirmDialogOpen: false });

    const handleOpenConfirmDialog = (id) => {
        setConfirmar({ funcionarioId: id, confirmDialogOpen: true });
    };

    const handleCloseConfirmDialog = () => {
        setConfirmar({ funcionarioId: null, confirmDialogOpen: false });
    };

    const handleDelete = () => {
        const idToDelete = confirmar.funcionarioId.id;
        UsersService.Delete(idToDelete, 'funcionario')
            .then((res) => {
                dispatch(changeloading({ open: false }));
                dispatch(changeNotify({ open: true, class: 'success', msg: res.message }));
                setTableUpdateTrigger((prev) => prev + 1);
            })
            .catch((error) => {
                dispatch(changeloading({ open: false }));
                dispatch(changeNotify({ open: true, class: 'error', msg: error.message }));
            });
        handleCloseConfirmDialog();
    };

    const handleCadastroConcluido = () => {
        setTableUpdateTrigger(prev => prev + 1);
        handleCloseModal();
    };


    return (
        <Box>
            <Box width='40%'>
                <TextField
                    label="Pesquisar"
                    value={busca}
                    onChange={(e) => setBusca(e.target.value)}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                        sx: {
                            lineHeight: '1.6',
                        },
                        inputProps: {
                            style: {
                                padding: '10px 10px',
                            },
                        },
                    }}
                    InputLabelProps={{
                        style: {
                            lineHeight: '1.6',
                        },
                    }}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                />
            </Box>
            <Confirm
                open={confirmar.confirmDialogOpen}
                title="Deseja realmente excluir esse funcionário?"
                onClose={handleCloseConfirmDialog}
                onConfirm={handleDelete}
            />
            <TableComponet
                headers={headers}
                data={funcionarios}
                labelCaption={'Nenhum funcionário encontrado!!'}
                labelTable={'Funcionários'}
                handlerEditarAction={(funcionarioId) => handleEdit(funcionarioId)}
                handlerDeletarAction={(event) => handleOpenConfirmDialog(event)}
                request
                qdtPage={totalPage}
                loading={loading}
                setData={setFuncionarios}
                tableUpdateTrigger={tableUpdateTrigger}
                handlerRequest={async (page, size) => {
                    setLoading(true);
                    UsersService.getPagination({ search: busca }, 'funcionario', page, size).then((data) => {
                        setLoading(false);
                        setFuncionarios(data.funcionarios || []);
                        setTotalPages(data.totalPages || 0);
                        return data;
                    });
                    return [];
                }}
            />
            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalStyle}>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <EditFuncionario
                            funcionario={selectedFuncionario}
                            handleCloseModal={handleCloseModal}
                            tableUpdateTriggerPai={handleCadastroConcluido}
                        />
                    </Box>
                </Box>
            </Modal>
        </Box>
    );
}

export default TabelaFuncionario;
